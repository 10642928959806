import { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import styled from "styled-components";

const DropdownContainer = styled.div`
	line-height: 24px;
	position: relative;
`;

const FieldContainer = styled.div<{ border?: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	width: 100%;
	height: 100%;
	padding: 12px 20px;
	box-sizing: border-box;
	font-size: 16px;
	border: ${(props) => (props.border ? "2px solid gray" : "none")};
	border-radius: 4px;
`;

const FloatingScrollContainer = styled.div<{
	elementsVisible: number;
	opened: boolean;
}>`
	position: absolute;
	height: calc(
		((24px + 2px + 2px + 12px + 12px) * ${(props) => props.elementsVisible}) +
			10px
	);
	background-color: white;
	display: ${(props) => (props.opened ? "block" : "none")};
	width: 100%;
	overflow-y: scroll;
	z-index: ${(props) => (props.opened ? 100 : -1)};
`;

const FloatingFieldContainer = styled.div`
	cursor: pointer;
	width: 100%;
	height: calc(24px + 2px + 2px + 12px + 12px); // 24px is the lineheight
	padding: 12px 20px;
	box-sizing: border-box;
	font-size: 16px;
	border: 2px solid gray;
	background-color: white;
`;

export default function RectangularDropdown(props: {
	featured: string;
	items: { text: string; key: string }[];
	onSelect: (key: string, text: string) => void;
	className?: string;
}) {
	const [opened, setOpened] = useState(false);

	return (
		<DropdownContainer className={props.className}>
			<FieldContainer
				border={true}
				onClick={() => {
					setOpened(!opened);
				}}
			>
				<div>{props.featured}</div>
				<div>{opened ? <FaChevronUp /> : <FaChevronDown />}</div>
			</FieldContainer>
			<FloatingScrollContainer elementsVisible={5} opened={opened}>
				{props.items.map((item, index) => (
					<FloatingFieldContainer
						key={item.key}
						onClick={() => {
							props.onSelect(item.key, item.text);
							setOpened(false);
						}}
						style={{
							display: opened ? "block" : "none",
							top: `calc((24px + 2px + 2px + 12px + 12px) * ${
								index + 1
							})`,
						}}
					>
						{item.text}
					</FloatingFieldContainer>
				))}
			</FloatingScrollContainer>
		</DropdownContainer>
	);
}
