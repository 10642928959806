import { useState, useEffect } from "react";
import styled from "styled-components";
import RectangularDropdown from "../components.tsx/RectangularDropdown";
import {
	RegularTitleTextBlock,
	SmallTextBlock,
	SmallTitleTextBlock,
} from "../components.tsx/text";
import { IUserOverview } from "../types/users";
import { countryListAllIsoData } from "../util/countries";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import {
	ILevel1SubmissionData,
	ILevelSubmissionArgs,
} from "../types/submissions";
import { RectangularButton } from "../components.tsx/buttons";
import {
	acceptSubmission,
	getPendingSubmission,
	loadUser,
	rejectSubmission,
} from "../util/api";
import { CircularProgress } from "@mui/material";

const Page = styled.div`
	padding: 20px 40px;
`;

const PaddedRegularTitle = styled(RegularTitleTextBlock)`
	padding: 20px 20px;
`;
const PaddedSmallTitle = styled(SmallTitleTextBlock)`
	padding: 10px 0px;
`;
const PaddedSmallText = styled(SmallTextBlock)`
	padding: 2.5px 0px 20px 0px;
`;

const SubmissionSection = styled.div`
	padding: 20px 20px;
	border: 2px solid black;
`;

function SubmissionPage() {
	const [user, setUser] = useState<IUserOverview | undefined>();
	const [submission, setSubmission] = useState<
		(ILevelSubmissionArgs & { createdAt: string; _id: string }) | undefined
	>();
	const [error, setError] = useState<string | undefined>();
	const [makingRequest, setMakingRequest] = useState<boolean>(false);

	const urlSearchParams = new URLSearchParams(window.location.search);
	const { submissionId, uid } = Object.fromEntries(urlSearchParams.entries());

	useEffect(() => {
		getPendingSubmission(uid, submissionId).then(([sub, err]) => {
			if (err) {
				setError(err);
			} else {
				setSubmission(sub);
				setError(undefined);
			}
		});
		loadUser(uid).then(([u, err]) => {
			if (err) {
				setError(err);
			} else {
				setUser(u);
				setError(undefined);
			}
		});
	}, [uid, submissionId]);

	const accept = () => {
		setMakingRequest(true);
		acceptSubmission(submissionId).then((err) => {
			if (err) {
				setMakingRequest(false);
				setError(err);
			} else {
				// @ts-ignore
				window.location.replace("/");
			}
		});
	};
	const reject = () => {
		setMakingRequest(true);
		rejectSubmission(submissionId).then((err) => {
			if (err) {
				setMakingRequest(false);
				setError(err);
			} else {
				// @ts-ignore
				window.location.replace("/");
			}
		});
	};

	if (!user && error) {
		return <div>Could not load user</div>;
	} else if (!submission && error) {
		return <div>Could not load submission</div>;
	}
	if (!user || !submission) {
		return <div>Loading</div>;
	}

	const submissionData = submission!.data as ILevel1SubmissionData;

	return (
		<Page>
			<RegularTitleTextBlock style={{ paddingBottom: 0 }}>
				{user!.firstName} {user!.lastName} - Level 1
			</RegularTitleTextBlock>
			<PaddedSmallText style={{ paddingBottom: 0 }}>
				{user!.country}
			</PaddedSmallText>
			<PaddedSmallText>{user!.email}</PaddedSmallText>
			<SubmissionSection>
				<RegularTitleTextBlock style={{ paddingBottom: 0 }}>
					Identity
				</RegularTitleTextBlock>
				<PaddedSmallTitle>
					What brought me here, and why does it matter to me?
				</PaddedSmallTitle>
				<PaddedSmallText>
					{submissionData.identity_whatAndWhy}
				</PaddedSmallText>
				<PaddedSmallTitle>
					3 words I feel describe a Climate Action Educator
				</PaddedSmallTitle>
				<PaddedSmallText>
					{submissionData.identity_climateActionEducatorWord1}
				</PaddedSmallText>
				<PaddedSmallText>
					{submissionData.identity_climateActionEducatorWord2}
				</PaddedSmallText>
				<PaddedSmallText>
					{submissionData.identity_climateActionEducatorWord3}
				</PaddedSmallText>
				<RegularTitleTextBlock style={{ paddingBottom: 0 }}>
					Action
				</RegularTitleTextBlock>
				<PaddedSmallTitle>
					Identify one area of need in terms of climate action in each
					of these areas:
				</PaddedSmallTitle>
				<PaddedSmallText>
					{submissionData.action_areaOfNeed_self}
				</PaddedSmallText>
				<PaddedSmallText>
					{submissionData.action_areaOfNeed_class}
				</PaddedSmallText>
				<PaddedSmallText>
					{submissionData.action_areaOfNeed_local}
				</PaddedSmallText>
				<PaddedSmallText>
					{submissionData.action_areaOfNeed_global}
				</PaddedSmallText>
				<PaddedSmallTitle>
					What is an action you would like to take this school year?
				</PaddedSmallTitle>
				<PaddedSmallText>
					{submissionData.action_ActionThisSchoolYear}
				</PaddedSmallText>
				<PaddedSmallTitle>
					If you could request one thing from this community of
					Climate Action Educators, what would it be? (examples of
					ideas: a specific lesson plan, a classroom to partner with,
					an example of a school-wide project to use as an exemplar,
					support on changing a policy) NOTE: We are building a
					network centered on action, so your request may be featured
					in the community for others to respond to and offer support.
					Financial requests will not be included.
				</PaddedSmallTitle>
				<PaddedSmallText>
					{submissionData.action_communityRequest}
				</PaddedSmallText>
				<RegularTitleTextBlock>Impact</RegularTitleTextBlock>
				<PaddedSmallTitle>
					Install EarthProject app. Go to “other” and submit your
					private code here.
				</PaddedSmallTitle>
				<PaddedSmallText>
					{submissionData.impact_appCode}
				</PaddedSmallText>
				<PaddedSmallTitle>
					Take at least 5 actions and submit the # of kg of carbon
					avoided and # of kg of plastic avoided. Share screenshot.
				</PaddedSmallTitle>
				<PaddedSmallText>
					{submissionData.impact_appSavings}
				</PaddedSmallText>
				<PaddedSmallTitle>App screenshot</PaddedSmallTitle>
				<img
					src={`/api/submissionImage?uid=${uid}&level=1&imgId=impact_appScreenshotPath`}
					alt="Submitted screenshot"
					style={{ maxWidth: "50%" }}
				/>
				<PaddedSmallTitle>
					With whom did you have your conversation?
				</PaddedSmallTitle>
				<PaddedSmallText>
					{submissionData.impact_conversationPartner}
				</PaddedSmallText>
				<PaddedSmallTitle>
					Why did you select this person?
					<br />
					What was their response to your commitment to climate action
					education?
				</PaddedSmallTitle>
				<PaddedSmallText>
					{submissionData.impact_conversationPartnerInfo}
				</PaddedSmallText>
				<PaddedSmallTitle>
					How did that conversation impact you or help shape the
					actions you want to take?
				</PaddedSmallTitle>
				<PaddedSmallText>
					{submissionData.impact_conversationPartnerResult}
				</PaddedSmallText>
				<PaddedSmallTitle>
					What was the response you received?
				</PaddedSmallTitle>
				<PaddedSmallText>
					{submissionData.impact_communityResponse}
				</PaddedSmallText>
				<PaddedSmallTitle>
					Share the link to your social media share (Twitter,
					Instagram, Linkedin, Facebook).
				</PaddedSmallTitle>
				{submissionData.impact_socialMediaLinks.map((link) => (
					<PaddedSmallText>{link}</PaddedSmallText>
				))}
				<PaddedSmallTitle>
					What is your biggest hope we can achieve as a community of
					Climate Action Educators?
				</PaddedSmallTitle>
				<PaddedSmallText>
					{submissionData.impact_communityBiggestHope}
				</PaddedSmallText>
			</SubmissionSection>
			<div style={{ paddingTop: 25 }}>
				{error ? <div>Error: {error}</div> : <></>}
				{makingRequest ? (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<CircularProgress />
						<div>
							Loading and contacting Edlusion (this could take a
							few seconds)
						</div>
					</div>
				) : (
					<>
						<RectangularButton
							backgroundColor="green"
							style={{ paddingBottom: 10 }}
							onClick={accept}
						>
							Accept
						</RectangularButton>
						<RectangularButton
							onClick={reject}
							backgroundColor="red"
						>
							Reject
						</RectangularButton>
					</>
				)}
			</div>
		</Page>
	);
}

export default SubmissionPage;
