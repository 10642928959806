import { useState, useEffect } from "react";
import styled from "styled-components";
import RectangularDropdown from "../components.tsx/RectangularDropdown";
import {
	RegularTitleTextBlock,
	SmallTextBlock,
	SmallTitleTextBlock,
} from "../components.tsx/text";
import { IUserOverview } from "../types/users";
import { useParams } from "react-router-dom";
import { loadUser, getPendingSubmissions } from "../util/api";
import { ILevelSubmissionArgs } from "../types/submissions";

const Page = styled.div`
	padding: 20px 40px;
`;

const PaddedSmallText = styled(SmallTextBlock)`
	padding: 2.5px 0px;
`;

const SubmissionSection = styled.div`
	padding: 20px 0px;
`;

const SubmissionsList = styled.div`
	padding: 0px 40px;
	display: flex;
	flex-direction: column;
`;

const SubmissionsListRow = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	cursor: pointer;
	border-top: 1px solid black;
`;

const SubmissionListRowEntry = styled(SmallTextBlock)`
	padding: 20px 20px;
	flex: 1;
`;

function UserPage() {
	const [user, setUser] = useState<IUserOverview | undefined>();
	const [submissions, setSubmissions] = useState<
		| (ILevelSubmissionArgs & { createdAt: string; _id: string })[]
		| undefined
	>();
	const [error, setError] = useState<string | undefined>();

	const urlSearchParams = new URLSearchParams(window.location.search);
	const { uid } = Object.fromEntries(urlSearchParams.entries());

	useEffect(() => {
		getPendingSubmissions(uid).then(([subs, err]) => {
			if (err) {
				setError(err);
			} else {
				setSubmissions(subs);
				setError(undefined);
			}
		});
		loadUser(uid).then(([u, err]) => {
			if (err) {
				setError(err);
			} else {
				setUser(u);
				setError(undefined);
			}
		});
	}, [uid]);

	if (!user && error) {
		return <div>Could not load user</div>;
	}
	if (!submissions && error) {
		return <div>Could not load submissions</div>;
	}
	if (!user || !submissions) {
		return <div>Loading</div>;
	}

	return (
		<Page>
			<RegularTitleTextBlock style={{ paddingBottom: 0 }}>
				{user!.firstName} {user!.lastName}
			</RegularTitleTextBlock>
			<PaddedSmallText>{user!.country}</PaddedSmallText>
			<PaddedSmallText>{user!.email}</PaddedSmallText>
			<SubmissionSection>
				<RegularTitleTextBlock style={{ paddingBottom: 20 }}>
					Submissions
				</RegularTitleTextBlock>
				<SubmissionsList>
					{!submissions.length ? <div>No submissions</div> : <></>}
					{submissions!.map((submission, index) => (
						<SubmissionsListRow
							key={index}
							onClick={() => {
								window.location.href = `/submission/level${submission.level}?uid=${uid}&submissionId=${submission._id}`;
							}}
						>
							<SubmissionListRowEntry>
								Level {submission.level}
							</SubmissionListRowEntry>
							<SubmissionListRowEntry>
								{submission.createdAt.substr(0, 10)}
							</SubmissionListRowEntry>
						</SubmissionsListRow>
					))}
				</SubmissionsList>
			</SubmissionSection>
		</Page>
	);
}

export default UserPage;
