import React, { ChangeEvent } from "react";
import styled, { StyledComponent } from "styled-components";
import { black } from "../globals/colors";

const RectangularTextFieldContainer = styled("div")<{
	textColor?: string;
}>`
	color: ${({ textColor }) => textColor || black};
	font-family: "Lato", sans-serif;
	text-align: left;
`;

const RectangularTextFieldInput: StyledComponent<
	"input",
	any,
	{
		border?: boolean | undefined;
	},
	never
> = styled.input<{ border?: boolean }>`
	width: 100%;
	height: 100%;
	padding: 12px 20px;
	box-sizing: border-box;
	font-size: 16px;
	border: ${(props) => (props.border ? "2px solid gray" : "none")};
	border-radius: 4px;
	&:disabled {
		background-color: #e7e7e7;
	}
`;
const RectangularTextAreaInput = styled.textarea<{ border?: boolean }>`
	width: 100%;
	height: 8em;
	padding: 12px 20px;
	box-sizing: border-box;
	font-size: 16px;
	border: ${(props) => (props.border ? "2px solid gray" : "none")};
	border-radius: 4px;
`;

export function RectangularTextField(props: {
	placeholder: string;
	id?: string;
	name?: string;
	border?: boolean;
	isSecure?: boolean;
	className?: string;
	maxlength?: number;
	maxLength?: number;
	disabled?: boolean;
	value?: string;
	style?: React.StyleHTMLAttributes<HTMLDivElement>;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}) {
	return (
		<RectangularTextFieldContainer
			style={props.style}
			className={props.className}
		>
			<RectangularTextFieldInput
				type={props.isSecure ? "password" : "text"}
				name={props.name}
				placeholder={props.placeholder}
				border={props.border}
				id={props.id}
				maxLength={props.maxlength || props.maxLength}
				disabled={props.disabled}
				value={props.value}
				onChange={props.onChange}
			/>
		</RectangularTextFieldContainer>
	);
}

export function RectangularTextArea(props: {
	placeholder: string;
	id?: string;
	name?: string;
	border?: boolean;
	className?: string;
	maxlength?: number;
	maxLength?: number;
	disabled?: boolean;
	value?: string;
	style?: React.StyleHTMLAttributes<HTMLDivElement>;
	onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}) {
	return (
		<RectangularTextFieldContainer
			style={props.style}
			className={props.className}
		>
			<RectangularTextAreaInput
				name={props.name}
				placeholder={props.placeholder}
				border={props.border}
				id={props.id}
				maxLength={props.maxlength}
				value={props.value}
				onChange={props.onChange}
			/>
		</RectangularTextFieldContainer>
	);
}
