import { ILevelSubmissionArgs } from "../types/submissions";
import { IUserOverview } from "../types/users";

export const apiUrl = process.env.NODE_ENV === "production" ? "https://admin.earthproject.org" : "http://localhost:8000/api";

const redirectIfNotLoggedIn = (body: { message?: string }, redirect?: string) => {
    if (body.message === "Not logged in.") {
        let href = "/login";
        if (redirect) {
            href += `?redirect=${redirect}`;
        }
        window.location.href = href;
        return true;
    }
}

export const loadUsers = async (page: number, query?: { country: string | undefined }): Promise<[(IUserOverview & { pendingSubmissionCount: number })[] | undefined, { totalCount: number, page: number, maxPageCount: number } | undefined, string | undefined]> => {
    let endpoint = `/api/users?page=${page}`;
    if (query && query.country) {
        endpoint += `&country=${query.country}`
    }
    const response = await fetch(endpoint);
    const body = await response.json();
    if (body.status !== "success") {
        if (redirectIfNotLoggedIn(body, "/")) {
            return [undefined, undefined, undefined];
        }
        return [undefined, undefined, body.message];
    }
    return [body.data, body.pagination, undefined];
};

export const loadUser = async (uid: string): Promise<[IUserOverview | undefined, string | undefined]> => {
    try {
        const response = await fetch(`/api/user?id=${uid}`);
        const t = await response.text();
        console.log(t);
        const body = JSON.parse(t);
        if (body.status !== "success") {
            if (redirectIfNotLoggedIn(body, "/")) {
                return [undefined, undefined];
            }
            return [undefined, body.message];
        }
        return [body.data, undefined];
    } catch (e) {
        return [undefined, "Could not load user data"];
    }
};

/**
 * 
 * @param email 
 * @param password 
 * @returns A string if an error occured.
 */
export const login = async (email: string, password: string): Promise<string | undefined> => {
    const response = await fetch(`/api/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email, password
        })
    });
    const body = await response.json();
    if (body.status !== "success") {
        return "Could not log in";
    }
    return;
};

export const getPendingSubmissions = async (userId: string, level?: string): Promise<[(ILevelSubmissionArgs & { createdAt: string, _id: string })[] | undefined, string | undefined]> => {
    let queryStr = `userId=${userId}`;
    if (level) {
        queryStr += `&level=${level}`;
    }
    const response = await fetch(`/api/pendingSubmissions?${queryStr}`);
    const body = await response.json();
    if (body.status !== "success") {
        if (redirectIfNotLoggedIn(body, "/")) {
            return [undefined, undefined];
        }
        return [undefined, body.message];
    }
    return [body.data, undefined];
};

export const getPendingSubmission = async (userId: string, submissionId: string): Promise<[(ILevelSubmissionArgs & { createdAt: string, _id: string }) | undefined, string | undefined]> => {
    const response = await fetch(`/api/pendingSubmission?userId=${userId}&submissionId=${submissionId}`);
    const body = await response.json();
    if (body.status !== "success") {
        if (redirectIfNotLoggedIn(body, "/")) {
            return [undefined, undefined];
        }
        return [undefined, body.message];
    }
    return [body.data, undefined];
};

export const acceptSubmission = async (submissionId: string): Promise<string | undefined> => {
    const response = await fetch(`/api/acceptSubmission?submissionId=${submissionId}`, {
        method: "POST",
    });
    const body = await response.json();
    if (body.status !== "success") {
        if (redirectIfNotLoggedIn(body, "/")) {
            return undefined;
        }
        return body.message;
    }
    return;
};

export const rejectSubmission = async (submissionId: string): Promise<string | undefined> => {
    const response = await fetch(`/api/rejectSubmission?submissionId=${submissionId}`, {
        method: "POST",
    });
    const body = await response.json();
    if (body.status !== "success") {
        if (redirectIfNotLoggedIn(body, "/")) {
            return undefined;
        }
        return body.message;
    }
    return;
};