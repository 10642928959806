import { useState, useEffect } from "react";
import styled from "styled-components";
import RectangularDropdown from "../components.tsx/RectangularDropdown";
import {
	RegularTitleTextBlock,
	SmallTextBlock,
	SmallTitleTextBlock,
} from "../components.tsx/text";
import { IUserOverview } from "../types/users";
import { countryListAllIsoData } from "../util/countries";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { loadUsers } from "../util/api";

const Page = styled.div``;
const TopBar = styled.div`
	padding: 20px 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	/* justify-content: space-between; */
`;
const PaddedRegularTitle = styled(RegularTitleTextBlock)`
	padding: 20px 20px;
`;
const PaddedDropdown = styled(RectangularDropdown)`
	padding: 20px 20px;
	width: 500px;
`;

const PaddedSmallTitle = styled(SmallTitleTextBlock)`
	padding: 20px 20px;
`;

const UsersListRow = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	cursor: pointer;
`;
const UserListRowEntry = styled(SmallTextBlock)`
	padding: 20px 20px;
	flex: 1;
`;

const UsersListHeader = styled.div`
	padding: 0px 40px;
	display: flex;
	flex-direction: row;
`;

const UsersList = styled.div`
	padding: 0px 40px;
	display: flex;
	flex-direction: column;
`;
const PaginationContainer = styled.div`
	padding: 40px 40px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;
const Clickable = styled.div`
	cursor: pointer;
`;

function UsersListPage() {
	const [selectedCountry, setSelectedCountry] = useState<
		{ code: string; name: string } | undefined
	>();
	const [selectedLevel, setSelectedLevel] = useState<string | undefined>();
	const [users, setUsers] = useState<
		(IUserOverview & { pendingSubmissionCount: number })[]
	>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [page, setPage] = useState(1);
	const [numberOfPages, setNumberOfPages] = useState(1);
	const [error, setError] = useState<string | undefined>();

	useEffect(() => {
		loadUsers(page, { country: selectedCountry?.code }).then(
			([result, pagination, err]) => {
				if (err) {
					console.log(err);
					setError(err);
				} else {
					if (!pagination) {
						setPage(1);
					} else if (pagination!.page !== page) {
						setPage(pagination!.page);
					}
					console.log(result);
					setUsers(result!);
					setLoading(false);
					if (pagination) {
						setNumberOfPages(
							Math.ceil(
								pagination!.totalCount /
									pagination!.maxPageCount
							)
						);
					}
					setError(undefined);
				}
			}
		);
	}, [page, selectedCountry]);

	const createUsersList = () => {
		if (loading) return <div>Loading</div>;
		if (error) return <div>{error}</div>;
		if (!users.length) return <div>No users found</div>;
		return (
			<>
				<UsersList>
					{users.map((u) => (
						<UsersListRow
							key={u._id}
							onClick={() =>
								(window.location.href = `/user?uid=${u._id}`)
							}
						>
							<UserListRowEntry>{u.lastName}</UserListRowEntry>
							<UserListRowEntry>{u.firstName}</UserListRowEntry>
							<UserListRowEntry>{u.country}</UserListRowEntry>
							<UserListRowEntry>{u.email}</UserListRowEntry>
							<UserListRowEntry>
								{u.pendingSubmissionCount}
							</UserListRowEntry>
						</UsersListRow>
					))}
				</UsersList>
				<PaginationContainer>
					<Clickable
						onClick={() => (page > 1 ? setPage(page - 1) : null)}
					>
						<FaChevronLeft />
					</Clickable>
					<PaddedSmallTitle>
						Page {page}/{numberOfPages}
					</PaddedSmallTitle>
					<Clickable
						onClick={() =>
							page < numberOfPages ? setPage(page + 1) : null
						}
					>
						<FaChevronRight />
					</Clickable>
				</PaginationContainer>
			</>
		);
	};

	return (
		<Page>
			<TopBar>
				<PaddedRegularTitle>Users</PaddedRegularTitle>
				<PaddedDropdown
					items={[
						{ key: "-1", text: "All" },
						...countryListAllIsoData.map((c) => ({
							key: c.code,
							text: c.name,
						})),
					]}
					featured={
						selectedCountry ? selectedCountry.name : "Country"
					}
					onSelect={(key, text) =>
						setSelectedCountry(
							key === "-1" ? undefined : { code: key, name: text }
						)
					}
				/>
				<PaddedDropdown
					items={[
						{ key: "-1", text: "All" },
						{ key: "1", text: "1" },
					]}
					featured={`Level: ${selectedLevel ?? "All"}`}
					onSelect={(key) =>
						setSelectedLevel(key === "-1" ? undefined : key)
					}
				/>
			</TopBar>
			<UsersListHeader>
				<PaddedSmallTitle style={{ flex: 1 }}>
					Last Name
				</PaddedSmallTitle>
				<PaddedSmallTitle style={{ flex: 1 }}>
					First Name
				</PaddedSmallTitle>
				<PaddedSmallTitle style={{ flex: 1 }}>Country</PaddedSmallTitle>
				<PaddedSmallTitle style={{ flex: 1 }}>Email</PaddedSmallTitle>
				<PaddedSmallTitle style={{ flex: 1 }}>
					Pending submissions
				</PaddedSmallTitle>
			</UsersListHeader>
			{createUsersList()}
		</Page>
	);
}

export default UsersListPage;
