import React from "react";
import styled from "styled-components";
import { blue } from "../globals/colors";

const _RectangularButton = styled("div")<{
	backgroundColor?: string;
	textColor?: string;
	horizontalAlignment?: "start" | "center" | "end";
}>`
	background-color: ${({ backgroundColor }) => backgroundColor || blue};
	color: ${({ textColor }) => textColor || "white"};
	font-family: "Lato", sans-serif;
	font-size: 23px;
	padding: 15px 20px;
	text-align: center;
	display: flex;
	justify-content: ${(props) => props.horizontalAlignment || "center"};
	align-items: center;
	cursor: pointer;
`;

export function RectangularButton(props: {
	backgroundColor?: string;
	textColor?: string;
	children?: React.ReactNode;
	submitsForm?: boolean;
	formId?: string;
	onClick?: () => void;
	href?: string;
	className?: string;
	openInCurrentTab?: boolean;
	horizontalAlignment?: "start" | "center" | "end";
	style?: React.CSSProperties;
}) {
	if (props.submitsForm) {
		if (!props.formId) {
			throw Error("Button can't submit form without form id");
		} else {
			return (
				<div
					className={props.className}
					onClick={() => {
						if (props.onClick) {
							props.onClick();
						}
						//@ts-ignore
						document.getElementById(props.formId).submit();
					}}
				>
					<_RectangularButton
						backgroundColor={props.backgroundColor}
						textColor={props.textColor}
					>
						{props.children}
					</_RectangularButton>
				</div>
			);
		}
	} else {
		return (
			<div style={props.style} className={props.className}>
				<a
					href={props.href!}
					target={props.openInCurrentTab ? "_self" : "_blank"}
					rel="noreferrer"
				>
					<_RectangularButton
						backgroundColor={props.backgroundColor}
						textColor={props.textColor}
						onClick={props.href ? () => {} : props.onClick}
						horizontalAlignment={props.horizontalAlignment}
					>
						{props.children}
					</_RectangularButton>
				</a>
			</div>
		);
	}
}
