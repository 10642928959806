import styled from "styled-components";

export const BigTitleTextBlock = styled.div`
	text-align: center;
	font-family: "Lato", sans-serif;
	font-weight: 600;
	font-size: 43px;
`;

export const MediumTitleTextBlock = styled.div`
	text-align: center;
	font-family: "Lato", sans-serif;
	font-weight: normal;
	font-size: 32px;
`;

export const MediumTextBlock = styled.div`
	font-family: "Lato", sans-serif;
	font-weight: normal;
	font-size: 27px;
`;

export const RegularTitleTextBlock = styled.div`
	font-family: "Lato", sans-serif;
	font-weight: 500;
	font-size: 25px;
`;

export const RegularTextBlock = styled.div`
	font-family: "Lato", sans-serif;
	font-weight: 300;
	font-size: 20px;
	line-height: 1.5;
`;

export const BigTextContentBlock = styled.div`
	font-family: "Lato", sans-serif;
	font-weight: 300;
	font-size: 35px;
`;

export const SmallTitleTextBlock = styled.div`
	font-family: "Lato", sans-serif;
	font-weight: 500;
	font-size: 20px;
`;

export const SmallTextBlock = styled.div`
	font-family: "Lato", sans-serif;
	font-weight: 300;
	font-size: 16px;
`;

export const ErrorTextBlock = styled(SmallTextBlock)`
	color: red;
`;
