import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import UsersListPage from "./pages/UsersListPage";
import reportWebVitals from "./reportWebVitals";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import UserPage from "./pages/UserPage";
import SubmissionPage from "./pages/SubmissionPage";
import SubmissionPageLevel2 from "./pages/SubmissionPageLevel2";
import LoginPage from "./pages/Login";

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Switch>
				<Route exact path="/" component={UsersListPage} />
				<Route exact path="/login" component={LoginPage} />
				<Route exact path="/user" component={UserPage} />
				<Route
					exact
					path="/submission/level1"
					component={SubmissionPage}
				/>
				<Route
					exact
					path="/submission/level2"
					component={SubmissionPageLevel2}
				/>
			</Switch>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
