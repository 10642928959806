import { useState, useEffect } from "react";
import styled from "styled-components";
import {
	RegularTitleTextBlock,
	SmallTextBlock,
	SmallTitleTextBlock,
} from "../components.tsx/text";
import { IUserOverview } from "../types/users";
import { countryCodeToName } from "../util/countries";
import {
	ILevel2SubmissionCapPathData,
	ILevel2SubmissionLevelPathData,
	ILevelSubmissionArgs,
} from "../types/submissions";
import { RectangularButton } from "../components.tsx/buttons";
import {
	acceptSubmission,
	getPendingSubmission,
	loadUser,
	rejectSubmission,
} from "../util/api";
import { CircularProgress } from "@mui/material";

const Page = styled.div`
	padding: 20px 40px;
`;

const PaddedSmallTitle = styled(SmallTitleTextBlock)`
	padding: 10px 0px;
`;
const PaddedSmallText = styled(SmallTextBlock)`
	padding: 2.5px 0px 20px 0px;
`;

const SubmissionSection = styled.div`
	padding: 20px 20px;
	border: 2px solid black;
`;

function SubmissionPage() {
	const [user, setUser] = useState<IUserOverview | undefined>();
	const [submission, setSubmission] = useState<
		(ILevelSubmissionArgs & { createdAt: string; _id: string }) | undefined
	>();
	const [error, setError] = useState<string | undefined>();
	const [makingRequest, setMakingRequest] = useState<boolean>(false);

	const urlSearchParams = new URLSearchParams(window.location.search);
	const { submissionId, uid } = Object.fromEntries(urlSearchParams.entries());

	useEffect(() => {
		getPendingSubmission(uid, submissionId).then(([sub, err]) => {
			if (err) {
				setError(err);
			} else {
				setSubmission(sub);
				setError(undefined);
			}
		});
		loadUser(uid).then(([u, err]) => {
			if (err) {
				setError(err);
			} else {
				setUser(u);
				setError(undefined);
			}
		});
	}, [uid, submissionId]);

	const accept = () => {
		setMakingRequest(true);
		acceptSubmission(submissionId).then((err) => {
			if (err) {
				setMakingRequest(false);
				setError(err);
			} else {
				// @ts-ignore
				window.location.replace("/");
			}
		});
	};
	const reject = () => {
		setMakingRequest(true);
		rejectSubmission(submissionId).then((err) => {
			if (err) {
				setMakingRequest(false);
				setError(err);
			} else {
				// @ts-ignore
				window.location.replace("/");
			}
		});
	};

	const createCapPathSubmissionSection = () => {
		const data = submission!.data as ILevel2SubmissionCapPathData;
		return (
			<SubmissionSection>
				<RegularTitleTextBlock style={{ paddingBottom: 0 }}>
					Identity
				</RegularTitleTextBlock>
				<PaddedSmallTitle>Country</PaddedSmallTitle>
				<PaddedSmallText>
					{countryCodeToName(data.identity_country)}
				</PaddedSmallText>
				<RegularTitleTextBlock style={{ paddingBottom: 0 }}>
					Action
				</RegularTitleTextBlock>
				<PaddedSmallTitle>
					Did you participate in the 6-week Climate Action Project as
					a teacher leading a classroom? Please share the link to your
					Learner Wallet showing your Climate Action Project Educator
					badge:
					<br />
					https://my.learnerwallet.com
				</PaddedSmallTitle>
				<PaddedSmallText>{data.action_link}</PaddedSmallText>
				<RegularTitleTextBlock>Impact</RegularTitleTextBlock>
				<PaddedSmallTitle>
					Describe your solution or idea for what might be “possible”
					for climate education based on your work as part of the
					Climate Action Project.
				</PaddedSmallTitle>
				<PaddedSmallText>{data.impact_solution}</PaddedSmallText>
				<PaddedSmallTitle>
					What is your biggest hope that can be achieved within the
					next year?
				</PaddedSmallTitle>
				<PaddedSmallText>{data.impact_hope}</PaddedSmallText>
				<PaddedSmallTitle>
					What is one asset you identified that may be of interest or
					benefit to the global community?
				</PaddedSmallTitle>
				<PaddedSmallText>{data.impact_asset}</PaddedSmallText>
			</SubmissionSection>
		);
	};

	const createLevelPathSubmissionSection = () => {
		const data = submission!.data as ILevel2SubmissionLevelPathData;
		return (
			<SubmissionSection>
				<RegularTitleTextBlock style={{ paddingBottom: 0 }}>
					Identity
				</RegularTitleTextBlock>
				<PaddedSmallTitle>School</PaddedSmallTitle>
				<PaddedSmallText>{data.identity_school}</PaddedSmallText>
				<PaddedSmallTitle>
					What do you see working in your school in terms of climate
					education? Please share several sentences on the current
					state of climate education at that level?
				</PaddedSmallTitle>
				<PaddedSmallText>
					{data.identity_whatWorksInSchool}
				</PaddedSmallText>
				<PaddedSmallTitle>Country</PaddedSmallTitle>
				<PaddedSmallText>
					{countryCodeToName(data.identity_country)}
				</PaddedSmallText>
				<PaddedSmallTitle>
					What do you see working in your country in terms of climate
					education? Please share several sentences on the current
					state of climate education at that level?
				</PaddedSmallTitle>
				<PaddedSmallText>
					{data.identity_whatWorksInCountry}
				</PaddedSmallText>
				<PaddedSmallTitle>
					What is working? Share the listing of resources you curated.
				</PaddedSmallTitle>
				<PaddedSmallText>
					{data.identity_resources.map((c, i) => (
						<div style={{ paddingBottom: 10 }}>
							<ul>
								<li>
									<b>Title</b>: {c.title}
								</li>
								<li>
									<b>Source</b>: {c.source}
								</li>
								<li>
									<b>Link</b>: {c.link}
								</li>
							</ul>
						</div>
					))}
				</PaddedSmallText>
				<RegularTitleTextBlock style={{ paddingBottom: 0 }}>
					Action
				</RegularTitleTextBlock>
				<PaddedSmallTitle>
					Consider who is doing work currently for climate education
					in your country. Add their names and information and then
					think to the future!
				</PaddedSmallTitle>
				<PaddedSmallText>
					{data.action_individuals.map((c, i) => (
						<div style={{ paddingBottom: 10 }}>
							<ul key={`${c.name}${i}`}>
								<li>
									<b>Name</b>: {c.name}
								</li>
								<li>
									<b>Role</b>: {c.role}
								</li>
								<li>
									<b>Twitter handle</b>: {c.twitter}
								</li>
								<li>
									<b>
										Current contributions to climate
										education
									</b>
									: {c.contributions}
								</li>
								<li>
									<b>
										What are their gifts? What might be
										possible?
									</b>
									: {c.gifts}
								</li>
							</ul>
						</div>
					))}
				</PaddedSmallText>
				<RegularTitleTextBlock>Impact</RegularTitleTextBlock>
				<PaddedSmallTitle>Asset image</PaddedSmallTitle>
				<img
					src={`/api/submissionImage?uid=${uid}&level=2&imgId=impact_assetMapImg`}
					alt="Submitted asset"
					style={{ maxWidth: "50%" }}
				/>
				<PaddedSmallTitle>
					Describe your idea for what might be “possible” for climate
					education. Be sure to share if you envision this idea
					working at your local level or at the national level.
				</PaddedSmallTitle>
				<PaddedSmallText>{data.impact_possible}</PaddedSmallText>
				<PaddedSmallTitle>
					Share the link to your social media share (Twitter,
					Instagram, Linkedin, Facebook).
				</PaddedSmallTitle>
				<PaddedSmallText>{data.impact_socialLink}</PaddedSmallText>
				<PaddedSmallTitle>
					What is your biggest hope that can be achieved within the
					next year?
				</PaddedSmallTitle>
				<PaddedSmallText>{data.impact_hope}</PaddedSmallText>
				<PaddedSmallTitle>
					What is one asset you identified that may be of interest or
					benefit to the global community?
				</PaddedSmallTitle>
				<PaddedSmallText>{data.impact_asset}</PaddedSmallText>
			</SubmissionSection>
		);
	};

	if (!user && error) {
		return <div>Could not load user</div>;
	} else if (!submission && error) {
		return <div>Could not load submission</div>;
	}
	if (!user || !submission) {
		return <div>Loading</div>;
	}

	return (
		<Page>
			<RegularTitleTextBlock style={{ paddingBottom: 0 }}>
				{user!.firstName} {user!.lastName} - Level 2
			</RegularTitleTextBlock>
			<PaddedSmallText style={{ paddingBottom: 0 }}>
				{user!.country}
			</PaddedSmallText>
			<PaddedSmallText>{user!.email}</PaddedSmallText>
			{submission.path === "LEVEL" ? (
				createLevelPathSubmissionSection()
			) : submission.path === "CAP" ? (
				createCapPathSubmissionSection()
			) : (
				<></>
			)}
			<div style={{ paddingTop: 25 }}>
				{error ? <div>Error: {error}</div> : <></>}
				{makingRequest ? (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<CircularProgress />
						<div>
							Loading and contacting Edlusion (this could take a
							few seconds)
						</div>
					</div>
				) : (
					<>
						<RectangularButton
							backgroundColor="green"
							style={{ paddingBottom: 10 }}
							onClick={accept}
						>
							Accept
						</RectangularButton>
						<RectangularButton
							onClick={reject}
							backgroundColor="red"
						>
							Reject
						</RectangularButton>
					</>
				)}
			</div>
		</Page>
	);
}

export default SubmissionPage;
